import { DAY_MONTH_YEAR, END_DATE_OF_MONTH, START_DATE_OF_MONTH } from '@/libs/filterDate'
import moment from 'moment'

export const chartOptions = {
  colors: ['#E31A1A'],
  stroke: {
    curve: 'smooth',
  },
  noData: {
    text: 'Tidak ada data untuk ditampilkan',
  },
  grid: {
    yaxis: {
      lines: {
        show: false,
      },
    },
  },
  chart: {
    toolbar: {
      show: false,
    },
    zoom: {
      enabled: false,
    },
  },
  plotOptions: {
    bar: {
      columnWidth: '40%',
      distributed: true,
    },
  },
  dataLabels: {
    enabled: false,
  },
  legend: {
    show: false,
  },
  yaxis: {
    show: false,
  },
  xaxis: {
    type: 'datetime',
    min: START_DATE_OF_MONTH,
    max: END_DATE_OF_MONTH,
    categories: [],
    labels: {
      formatter: (val, timestamp) => moment(new Date(timestamp)).format('DD'),
    },
  },
  tooltip: {
    shared: false,
    custom: ({
      series, seriesIndex, dataPointIndex, w,
    }) => {
      const seriesValue = series[seriesIndex][dataPointIndex]
      const seriesDate = w.config.xaxis.categories[dataPointIndex]

      return `<div class="p-[10px] rounded-lg" style="box-shadow: 2px 2px 4px 0px #00000029;">
          <div class="flex items-center gap-4">
            <img src="https://storage.googleapis.com/komerce/icon/ie7/info-circle.svg" alt="Komerce" />
            <div class="text-[#E31A1A]">Card Detected</div>
          </div>
          <br />
          <div class="flex flex-col gap-4">
            <div class="flex items-center gap-4">
              <img src="https://storage.googleapis.com/komerce/assets/komerce-icon/Hitam/card.svg" alt="Komerce" width="20"/>
              <div>Total Cards : ${seriesValue}</div>
            </div>
            <div class="flex items-center gap-4">
              <img src="https://storage.googleapis.com/komerce/assets/komerce-icon/Hitam/calendar.svg" alt="Komerce" width="20"/>
              <div>Tanggal : ${DAY_MONTH_YEAR(seriesDate)}</div>
            </div>
          </div>
        </div>`
    },
  },
}

export const fields = [
  {
    key: 'card_name',
    label: 'Card Name',
    class: 'p-1 align-middle',
    thStyle: {
      fontSize: '14px',
      color: 'black',
      fontWeight: 500,
      textTransform: 'capitalize',
    },
  },
  {
    key: 'created_at',
    label: 'Created At',
    class: 'p-1 align-middle',
    thStyle: {
      fontSize: '14px',
      color: 'black',
      fontWeight: 500,
      textTransform: 'capitalize',
    },
    formatter: value => moment(value, 'DD-MM-YYYY').format('DD MMMM YYYY'),

  },
  {
    key: 'insufficient_balance_dates',
    label: 'Date Insufficient balance',
    class: 'p-1 align-middle',
    thStyle: {
      fontSize: '14px',
      color: 'black',
      fontWeight: 500,
      textTransform: 'capitalize',
    },
  },
]
