<template>
  <div class="flex flex-col gap-[10px] text-[#626262]">
    <div class="bg-white p-1 rounded-lg">
      <div class="font-semibold text-black text-2xl">
        Insufficient Balance Information
      </div>
      <hr class="my-1">
      <div class="text-lg font-semibold">
        Partner Information
      </div>
      <div class="flex flex-col gap-5 mt-2 text-lg">
        <div
          v-for="item in user"
          :key="item.key"
          class="flex items-center justify-between"
        >
          <div class="capitalize">
            {{ item.key }}
          </div>
          <div>{{ item.value }}</div>
        </div>
      </div>
    </div>
    <div class="flex flex-col gap-10 bg-white p-1 rounded-lg">
      <div class="flex items-center justify-between">
        <div class="text-lg font-semibold">
          Date Insufficient Balance
        </div>
        <div class="w-[120px]">
          <FlatPickr
            v-model="period"
            :config="configDate"
            class="custom-select"
          />
        </div>
      </div>
      <BOverlay
        :show="isLoadingGraph"
        spinner-variant="primary"
        variant="light"
        blur="0"
        opacity=".5"
        rounded="sm"
      >
        <Apexcharts
          ref="myChart"
          height="400"
          :series="series"
          type="bar"
          :options="chartOptions"
        />
      </BOverlay>
      <div class="flex items-center justify-between">
        <div class="text-lg font-semibold">
          Card Insufficient Balance
        </div>
        <BButton
          :variant="insufficient ? 'primary' : 'outline-secondary'"
          size="sm"
          @click="toggleInsufficient"
        >
          Insufficient Balance
          <span
            v-if="insufficient"
            class="bg-white rounded-full text-[#F95031] px-1"
          >
            {{ items.length }}
          </span>
        </BButton>
      </div>
      <BOverlay
        :show="isLoadingDetail"
        spinner-variant="primary"
        variant="light"
        blur="0"
        opacity=".5"
        rounded="sm"
      >
        <BTable
          :items="items"
          :fields="fields"
          show-empty
          empty-text="Tidak ada data untuk ditampilkan"
          responsive
        >
          <template #cell(card_name)="data">
            <div class="flex items-center gap-5">
              <img
                src="https://storage.googleapis.com/komerce/assets/komerce-icon/Hitam/card.svg"
                alt="Komerce"
                width="20"
              >
              <div>{{ data.item.card.name }}</div>
              <div class="text-[#828282]">
                ***{{ data.item.card.last_number }}
              </div>
            </div>
          </template>
          <template #cell(insufficient_balance_dates)="data">
            <div v-if="isEmpty(data.item.insufficient_balance_dates)">
              -
            </div>
            <div
              v-for="(e, idx) in data.item.insufficient_balance_dates"
              v-else
              :key="idx"
              class="text-danger flex"
            >
              ({{ e }})
            </div>
          </template>
        </BTable>
      </BOverlay>
    </div>
  </div>
</template>

<script>
import { newAxiosIns } from '@/libs/axios'
import { alertError } from '@/@core/utils/toast'
import Apexcharts from 'vue-apexcharts'
import FlatPickr from 'vue-flatpickr-component'
import { Indonesian } from 'flatpickr/dist/l10n/id'
import '@/@core/scss/vue/libs/vue-flatpicker.scss'
import 'flatpickr/dist/plugins/monthSelect/style.css'
import MonthSelect from 'flatpickr/dist/plugins/monthSelect'
import { today } from '@/store/helpers'
import { YEAR_MONTH, DAY_MONTH_YEAR, YEAR_MONTH_DAY } from '@/libs/filterDate'
import isEmpty from 'lodash/isEmpty'
import { chartOptions, fields } from './config'

export default {
  components: { Apexcharts, FlatPickr },
  data() {
    return {
      isEmpty,
      DAY_MONTH_YEAR,
      user: [],
      items: [],
      fields,
      series: [],
      useId: this.$route.params.id,
      isLoadingGraph: false,
      isLoadingDetail: false,
      chartOptions,
      period: today,
      insufficient: true,
      configDate: {
        mode: 'single',
        locale: Indonesian,
        altInput: true,
        altFormat: 'j/n/Y',
        dateFormat: 'Y-m-d',
        maxDate: today,
        disableMobile: true,
        plugins: [
          new MonthSelect({
            shorthand: true,
            dateFormat: 'Y-m-d',
            altFormat: 'M Y',
          }),
        ],
      },
    }
  },
  watch: {
    period: {
      handler() {
        this.analyticInffucient()
        this.listInffucient()
      },
    },
    insufficient: {
      handler(e) {
        this.listInffucient()
      },
    },
  },
  mounted() {
    this.getInfoUser()
    this.analyticInffucient()
    this.listInffucient()
  },
  methods: {
    async getInfoUser() {
      const url = `auth/api/v1/admin/user/${this.useId}/info`

      await newAxiosIns.get(url)
        .then(res => {
          const { data: { data } } = res
          const transformData = [
            {
              key: 'fullname',
              value: data.name,
            },
            {
              key: 'email',
              value: data.email,
            },
            {
              key: 'Total Cards',
              value: data.total_card,
            },
          ]
          this.user = transformData
        })
        .catch(() => {
          alertError('Gagal load data user')
        })
    },
    async analyticInffucient() {
      this.isLoadingGraph = true
      const url = `/komcards/api/v1/admin/${this.useId}/insufficient-balance/graph`
      const params = {
        month: YEAR_MONTH(this.period),
      }
      await newAxiosIns.get(url, { params })
        .then(res => {
          const { data: { data } } = res
          this.isLoadingGraph = false

          const result = data.every(item => item.total_card === 0) ? [] : data

          this.series = [{ name: 'Total Cards', data: result.map(e => e.total_card) }]

          this.chartOptions = {
            ...this.chartOptions,
            xaxis: {
              ...this.chartOptions.xaxis,
              categories: data.map(e => e.date),
            },
          }
        })
        .catch(() => {
          this.isLoadingGraph = false
          alertError('Gagal load data graphic')
        })
    },
    async listInffucient() {
      this.isLoadingDetail = true
      const url = `/komcards/api/v1/admin/${this.useId}/insufficient-balance/detail`
      const params = {
        month: YEAR_MONTH(this.period),
        only_insufficient: this.insufficient,
      }
      await newAxiosIns.get(url, { params })
        .then(res => {
          const { data: { data } } = res
          this.isLoadingDetail = false
          this.items = isEmpty(data) ? [] : data
        })
        .catch(() => {
          this.isLoadingDetail = false
          alertError('Gagal load data graphic')
        })
    },
    toggleInsufficient() {
      this.insufficient = !this.insufficient
    },
  },
}
</script>
<style>
.flatpickr-monthSelect-month.selected{
  background-color: #F95031 !important;
}
</style>
